const determinarHost = () => {
    if (window.location.hostname.indexOf("localhost") !== -1) return "http://localhost:8000";
    else if (window.location.hostname.indexOf("192") !== -1) return "http://192.168.1.112:8000";
    else if (window.location.hostname.indexOf("pscaritassanmarcos") != -1) return "https://apiquazar.pscaritassanmarcos.org";
    else if (window.location.hostname.indexOf("adipo") !== -1) return "https://apiquazar.adipo.org.gt";
    else if (window.location.hostname.indexOf("xela") !== -1) return "http://www.apiquazar.socialxela.org";
    else if (window.location.hostname.indexOf("capi") !== -1) return "https://apiquazar.capistudioapps.com";
}

const BASE_URL = determinarHost()//'https://apicsm.capistudioapps.com'

// const BASE_URL = 'http://127.0.0.1:8000'

const tiempoEspera = 60000;

const descargarGET = (_URL) => {
    return new Promise(async (Resolve) => {

        let timerOut = null;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', _URL);
        xhr.timeout = tiempoEspera;

        // xhr.withCredentials = true;

        // xhr.onprogress = function (e) {
        //     if (_Actualizar != null) _Actualizar(e.loaded / e.total)
        // };
        xhr.ontimeout = function (e) {
            // alert("Tiempo Excedido")
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(null)
        };
        xhr.onload = function () {
            // console.log(_URL)
            // console.log(xhr.response)
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(xhr.response);
            // Resolve(false)
        };
        xhr.onerror = function () {
            // console.log("Error")
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(null);
        };
        try {
            xhr.send();
        } catch (error) {
            Resolve(null);
        }

        timerOut = setTimeout(() => {
            xhr.abort();
            // alert("Abortado");
            Resolve(null);

        }, tiempoEspera);
    });
}

// POST
const descargarPOST = (_URL, _FormData) => {
    return new Promise(async (Resolve) => {

        let timerOut = null;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', _URL);
        // xhr.withCredentials = true;
        // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.timeout = tiempoEspera;
        xhr.ontimeout = () => {
            if (timerOut != null) clearTimeout(timerOut);
            // alert('Timeout')
            Resolve(null);
        };
        xhr.onload = function () {
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(xhr.response);
        };
        xhr.onerror = function () {
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(null);
        };
        xhr.send(_FormData);

        timerOut = setTimeout(() => {
            xhr.abort();
            // alert("Abortado");
            Resolve(null);

        }, tiempoEspera);
    });
}

// POST Archivo
const ArchivoPOST = (_URL, _FormData) => {
    return new Promise(async (Resolve) => {

        let timerOut = null;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', _URL);
        // xhr.withCredentials = true;
        // xhr.setRequestHeader('Content-Type', 'multipart/form-data');
        xhr.timeout = tiempoEspera;
        xhr.ontimeout = () => {
            if (timerOut != null) clearTimeout(timerOut);
            // alert('Timeout')
            Resolve(null);
        };
        xhr.onload = function () {
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(xhr.response);
        };
        xhr.onerror = function () {
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(null);
        };
        xhr.send(_FormData);

        timerOut = setTimeout(() => {
            xhr.abort();
            // alert("Abortado");
            Resolve(null);

        }, tiempoEspera);
    });
}
//JSON FORMATER
const convertirJSON = (_Datos) => {
    try {

        if (_Datos === "" || _Datos === null) return null;
        const objeto = JSON.parse(_Datos)
        return objeto;

    } catch (e) {
        return null;
    }
}

const getCategorias = async () => {
    let datos = await descargarGET(`${BASE_URL}/recruitment`)
    datos = convertirJSON(datos);
    return datos;
}

const getPreguntas = async (id) => {
    let datos = await descargarGET(`${BASE_URL}/recruitment/${id}`)
    datos = convertirJSON(datos);
    return datos;
}

const postParticipante = async (_data) => {
    let datos = await descargarPOST(`${BASE_URL}/participation`, _data)
    datos = convertirJSON(datos);
    return datos;
}

const postRespuestas = async (_data) => {
    const res = await fetch(`${BASE_URL}/recruitmentResponse`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(_data)
    })

    return res.json()
}

const postCV = async (_IDReclutamiento, _dpi, _data) => {
    let datos = await ArchivoPOST(`${BASE_URL}/participation/storeCV/${_IDReclutamiento}/${_dpi}`, _data)
    datos = convertirJSON(datos);
    return datos;
}

export {
    getCategorias,
    getPreguntas,
    postParticipante,
    postRespuestas,
    postCV
}